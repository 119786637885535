import React, { useEffect, useState } from 'react';
import { Table, Spin, Layout } from 'antd';

const {Content} = Layout;

const DataPage = () => {
  const [isloading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([
    {
      '#': 1,
      kks: '10LCB11CT307',
      description: 'Helloowww',
      date: '2021-07-22 13:49:35',
      value: 78.0,
      inactive: 'True',
      remarks: 'All Okay',
    },
    {
      '#': 1,
      kks: '10LCB11CT307',
      description: 'Helloowww',
      date: '2021-07-22 13:49:35',
      value: 78.0,
      inactive: 'True',
      remarks: 'All Okay',
    },
    {
      '#': 1,
      kks: '10LCB11CT307',
      description: 'Helloowww',
      date: '2021-07-22 13:49:35',
      value: 78.0,
      inactive: 'True',
      remarks: 'All Okay',
    },
  ]);

  const columns = [
    {
      title: '#',
      dataIndex: 'kks',
      key: 'kks',
      render: (text, object, index) =>  index + 1
    },
    {
      title: 'KKS',
      dataIndex: 'kks',
      key: 'kks',
    },
    {
      title: 'DESCRPTION',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'DATE',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'VALUE',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'INACTIVE',
      dataIndex: 'inactive',
      key: 'inactive',
    },
    {
      title: 'REMARKS',
      dataIndex: 'remarks',
      key: 'remarks',
    },
  ]

  useEffect(() => {
    const options = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    fetch('https://dataloggingapp.herokuapp.com/get_input_data/', options)
      .then((resp) => resp.json())
      .then((response) => {
        setRows(response);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div style={{marginTop: 0}}>
      <Content className="site-layout" style={{ padding: '0 50px', marginTop: 120 }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
            {isloading ? (
              <div className="loader-container">
                <Spin />
              </div>
            ) : (
              <Table columns={columns} dataSource={rows}/>
            )}
      </div>
      </Content>
    </div>
  );
};

export default DataPage;
