import React, { useEffect, useState } from 'react';
import { Table, Spin, Layout, Descriptions, PageHeader } from 'antd';

const {Content} = Layout;
const {Column} = Table;



//Component START=======================================================================================
const ReportPage = () => {
  const [isloading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([
    {
      date: '2021-07-20',
      shift: 'Night Shift',
      kks_reading_percentage: 52.76,
      max_value_breached: 28,
      min_value_breached: 19,
      kks_inactive_count: 186,
    },
  ]);

  //Fetching Data--------------------------------------------------START
  useEffect(() => {
    const options = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    fetch('https://dataloggingapp.herokuapp.com/get_report_data/', options)
      .then((resp) => resp.json())
      .then((response) => {
        console.log(response);
        setRows(response);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  //Fetching Data-----------------------------------------------------END

  return (
    <div>
        <Content className="site-layout" style={{ padding: '0 50px', marginTop: 120}}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
          <div className='page-header-wrapper'>
          <PageHeader
            className="site-page-header"
            title="DATA LOG SUMMARY"
            subTitle="Summary of Metadata of the logged data"
            style={{marginLeft: -21, paddingTop: -100}}
          />
          </div>
          <br />
          <Descriptions size="small" column={2} bordered>
            <Descriptions.Item label="Morning Shift">0800 Hrs to 2000 Hrs</Descriptions.Item>
            <Descriptions.Item label="Night Shift">2000 Hrs to 0800 Hrs</Descriptions.Item>
            <Descriptions.Item label="Data Recorded %">Total Non Null Readings X 100 / Total KKS in Database - Total inactive KKS in Database</Descriptions.Item>
            <Descriptions.Item label="Total KKS">620</Descriptions.Item>
          </Descriptions>
          <br />
        {isloading ? (
          <div className="loader-container">
            <Spin />
          </div>
        ) : (
          <Table dataSource={rows}>
            <Column title="DATE" dataIndex="date" key="date" />
            <Column title="SHIFT" dataIndex="shift" key="shift" />
            <Column title="DATA RECORDED %" dataIndex="kks_reading_percentage" key="kks_reading_percentage" align='center' />
            <Column title="MAX VALUE BREACHED" dataIndex="max_value_breached" key="max_value_breached" align='center' />
            <Column title="MIN VALUE BREACHED" dataIndex="min_value_breached" key="min_value_breached" align='center' />
            <Column title="KKS INACTIVE COUNT" dataIndex="kks_inactive_count" key="kks_inactive_count" align='center' />
          </Table>
        )}
        </div>
        </Content>
    </div>
  );
};

export default ReportPage;
