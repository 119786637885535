import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'antd';

const { Meta } = Card;

export default function CardComponent({
  title,
  image,
  description,
  destination,
}) {
  return (
    <Fragment>
      <Card
        hoverable
        style={{ width: '32%' }}
        cover={<img alt="example" src={image} />}
      >
       <Link
          to={destination}
          style={{ textDecoration: 'inherit', color: 'inherit' }}
        >
      <Meta title= {title} description={description} />
      </Link>
    </Card>
    </Fragment>
  );
}
