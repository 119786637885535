import './App.less';

import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { Typography, Layout, Menu } from 'antd';
import HomePage from './Views/HomePage';
import DataPage from './Views/DataPage';
import ReportPage from './Views/ReportPage';

import OpslogLogo from './assets/images/Opslog_logo.png';

const {Title} = Typography;
const {Text} = Typography;
const {Header} = Layout;


function App() {
  return (
    <Router>
        <div>
          <nav>
            <Layout>
              <Header style={{ position: 'fixed', zIndex: 1, width: '100%', backgroundColor: '#1e784f' }}>
                <div className="logo-title-container">
                  <img src={OpslogLogo} className="Opslog-logo" />
                  <Title level={3} className='title-page'>Opslog</Title>
                </div>
                <Menu theme="light" mode="horizontal" defaultSelectedKeys={['1']}>
                  <Menu.Item key="1">
                    <Link
                      to="/"
                      style={{ textDecoration: 'inherit', color: 'inherit' }}
                    >
                     HOME
                    </Link>
                 
                  </Menu.Item>
                  <Menu.Item key="2">
                    <Link
                      to="/data"
                      style={{ textDecoration: 'inherit', color: 'inherit' }}
                    >
                     DATA
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <Link
                      to="/report"
                      style={{ textDecoration: 'inherit', color: 'inherit' }}
                    >
                     REPORT
                    </Link>
                  </Menu.Item>
                </Menu>
              </Header>
            </Layout>
          </nav>

          <Switch>
            <Route path="/report">
              <ReportPage />
            </Route>
            <Route path="/data">
              <DataPage />
            </Route>
            <Route path="/">
              <HomePage />
            </Route>
          </Switch>
        </div>
      </Router>
  );
}

export default App;
